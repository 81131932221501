<template>
	<div class='a-trips__sorting h-trips__sorting' :class='{"open": value}' v-if='filtersItems.length'>
		<div class="a-trips-mobile-header">
			<p class='a-trips-mobile-header-title'>{{$t("account.buttons.sorting")}}</p>
			<div class='a-trips-mobile-header-button ml-auto' @click='$emit("input", false)'>
				<v-icon icon='cross' size='17' />
			</div>
		</div>

		<div class='a-trips__sorting-wrap tablet-hide'>
			<p class='a-trips__sorting-title ml-auto'>{{$t("account.sort_by")}}:</p>

			<div class='a-trips__sorting-change'>
				<p class='a-trips__sorting-change-button' :class='{"active": active_sorting === "ranking"}' @click='$emit("active_sorting", "ranking")'>{{$t('account.search.hotels.sorting.best')}}</p>
				<p class='a-trips__sorting-change-button' :class='{"active": active_sorting === "discount"}' @click='$emit("active_sorting", "discount")'>{{$t('account.search.hotels.sorting.sale')}}</p>
				<p class='a-trips__sorting-change-button' :class='{"active": active_sorting === "minRate"}' @click='$emit("active_sorting", "minRate")'>{{$t('account.search.hotels.sorting.price')}}</p>
			</div>
		</div>

		<div class='a-trips__sorting-group tablet-show'>
			<div class='a-trips__sorting-group-radio'>
				<v-radio
					id="ranking"
					name='radio'
					:label="$t('account.search.hotels.sorting.best')"
					value='ranking'
					v-model='radio'
					@input='$emit("active_sorting", "ranking")'
				/>
				<v-radio
					id="discount"
					name='radio'
					:label="$t('account.search.hotels.sorting.sale')"
					value='discount'
					v-model='radio'
					@input='$emit("active_sorting", "discount")'
				/>
				<v-radio
					id="minRate"
					name='radio'
					:label="$t('account.search.hotels.sorting.price')"
					value='minRate'
					v-model='radio'
					@input='$emit("active_sorting", "minRate")'
				/>
			</div>
			<div class='a-trips__sorting-group-button'>
				<v-button small block color='green white--text' @click='$emit("input", false)'>{{$t("account.buttons.sorting")}}</v-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			sorting_open: false,
			radio: null
		}),
		props: ["value", "items", "filtersItems", "active_sorting"],
		created() {
			this.radio = this.active_sorting
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.h-trips__sorting {
		.a-trips__sorting {
			&-wrap {
				@include minw( $grid-breakpoints-sm ) {
					display: flex;
					border-bottom: none
				}
			}
			&-title {
				font-weight: 400;
				margin-right: 20px;
			}
			&-change {
				margin-bottom: 0;
				&-button {
					padding: 0 30px;
					white-space: nowrap;
				}
			}
			&-group {
				display: grid;
				grid-template-rows: 1fr auto;
				&-radio {
					.app-radio {
						margin-bottom: 20px;
						font-size: 16px;
						font-weight: 700;
					}
				}
				&-button {
					margin: 30px 0;
				}
			}
		}
		.a-trips-mobile-header-title {
			font-size: 20px;
			font-weight: 400;
		}
	}
</style>
