<template>
	<div class='h-total'>
		<div class='h-total-content'>
			<p class='h-total-sale' v-if='offer.discount'>
				<v-icon icon='tag' size='12' v-if='isIcon' />
				{{$helpers.amount(offer.amount + offer.discount.amount, offer.currency)}}
			</p>
			<p class='h-total-price'>{{$helpers.amount(offer.amount, offer.currency)}}</p>
		</div>
		<p class='h-total-descr'>{{$t('account.the_price_is_generally_for')}} {{night}} {{$helpers.declOfNum(night, $t('account.declOfNum.nights'))}}</p>
	</div>
</template>

<script>
	export default {
		props: ["isIcon", "offer", "night"]
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.h-total {
		text-align: right;
		&-content {
			display: flex;
			align-items: baseline;
			justify-content: flex-end;
			margin-bottom: 2px;
			flex-direction: row;
		}
		&-sale {
			display: flex;
			align-items: center;
			font-size: 16px * .9;
			font-weight: 400;
			color: rgba($cyprus, .5);
			text-decoration: line-through;
			margin-right: 12px * .8;
			@include minw($grid-breakpoints-lg) {
				font-size: 16px;
				margin-right: 12px;
			}
			.app-icon {
				color: $orange;
				margin-right: 12px * .8;
				@include minw($grid-breakpoints-lg) {
					margin-right: 12px;
				}
			}
		}
		&-price {
			font-size: 20px * .9;
			font-weight: 700;
			@include minw($grid-breakpoints-lg) {
				font-size: 20px;
			}
		}
		&-descr {
			font-size: 12px;
			font-weight: 400;
			color: rgba($black, .5);
		}
	}
</style>
