<template>
	<div class='a-sidebar' :class='{"open": value}'>
		<div class="a-trips-mobile-header">
			<p class='a-trips-mobile-header-title'>{{$t("account.sidebar.filters")}}</p>
			<p class='app-link green--text' @click='$emit("clear-filters"), createSlider()' v-if='Object.keys(filters).length'>{{$t("account.buttons.cast")}}</p>
			<div class='a-trips-mobile-header-button ml-auto' @click='$emit("input", false)'>
				<v-icon icon='cross' size='17'></v-icon>
			</div>
		</div>

		<div class="a-sidebar-total">
			<div class="row align-items-center">
				<div class="col">
					<p class='a-sidebar-total-title' v-if='filtersItems.length !== items.length'>{{$t("account.sidebar.shown")}} {{ filtersItems.length }} {{$t("account.with")}} {{ items.length }} {{$t("account.sidebar.hotels.hotels")}}</p>
					<p class='a-sidebar-total-title' v-else>{{$t("account.sidebar.total_number_of_options")}} - {{ items.length }}</p>
				</div>
				<div class="col-auto tablet-hide">
					<v-button outline xxsmall color="green" class='a-sidebar-total-button'
						@click='$emit("clear-filters"), createSlider()'
						v-if='filtersItems.length !== items.length'
					>{{$t("account.buttons.cast_all")}}</v-button>
				</div>
			</div>
		</div>

		<div class='a-sidebar-items'>
			<div class="row">
				<div class="col">
					<p class='a-sidebar-title'>{{$t("account.sidebar.hotels.name_hotel")}}</p>
				</div>
				<div class="col-auto" v-if='filters["name"] && filters["name"].length'>
					<p class='app-link green--text' @click='name = "", $emit("reset-filters", "name")'>{{$t("account.buttons.cast")}}</p>
				</div>
			</div>

			<v-text-field
				:placeholder='$t("account.sidebar.hotels.filter_by_hotel_name")'
				name='name'
				type='text'
				v-model='name'
				iconPrepend='search'
				:icon='name.length > 0 ? "cross" : ""'
				:iconAction='() => name = ""'
				:iconSize='12'
				isBorderFocus
			/>

			<template v-if='items_hotels.length === 0'>
				<p class='a-sidebar-total-title'>{{$t("account.sidebar.hotels.no_hotels_were_found")}}</p>
			</template>
			<sidebar-items
				:items="items_hotels"
				:reset='filters["name"] && filters["name"].length'
				@reset='$emit("reset-filters", "name")'
				@update='updateFilter(items_hotels, "name")'
				v-else
			/>
		</div>

		<sidebar-items
			:title='$t("account.sidebar.hotels.type_of_food")'
			:items="items_boardCode"
			:reset='filters["offers.boardCode"] && filters["offers.boardCode"].length'
			@reset='$emit("reset-filters", "offers.boardCode")'
			@update='updateFilter(items_boardCode, "offers.boardCode")'
			:all='{ id: "all-type", name: $t("account.sidebar.hotels.all_types"), total: items.length }'
		/>

		<sidebar-items
			:title='$t("account.sidebar.hotels.category")'
			:items="items_categories"
			:reset='filters["categorySimpleCode"] && filters["categorySimpleCode"].length'
			@reset='$emit("reset-filters", "categorySimpleCode")'
			@update='updateFilter(items_categories, "categorySimpleCode")'
		/>

		<div class='a-sidebar-items'>
			<div class="row">
				<div class="col">
					<p class='a-sidebar-title'>{{$t("account.sidebar.hotels.region")}}</p>
				</div>
				<div class="col-auto" v-if='filters["zoneName"] && filters["zoneName"].length'>
					<p class='app-link green--text' @click='zoneName = "", $emit("reset-filters", "zoneName")'>{{$t("account.buttons.cast")}}</p>
				</div>
			</div>

			<v-text-field
				:placeholder='$t("account.sidebar.hotels.filter_by_region")'
				name='zoneName'
				type='text'
				v-model='zoneName'
				iconPrepend='search'
				:icon='zoneName.length > 0 ? "cross" : ""'
				:iconAction='() => zoneName = ""'
				:iconSize='12'
				isBorderFocus
			/>

			<template v-if='items_zoneName.length === 0'>
				<p class='a-sidebar-total-title'>{{$t("account.sidebar.hotels.no_region_were_found")}}</p>
			</template>
			<sidebar-items
				:items='items_zoneName'
				:reset='filters["zoneName"] && filters["zoneName"].length'
				@reset='$emit("reset-filters", "zoneName")'
				@update='updateFilter(items_zoneName, "zoneName")'
				v-else
			/>
		</div>

		<sidebar-items
			:title='$t("account.sidebar.hotels.type_hotel")'
			:items="items_accommodation"
			:reset='filters["accommodationTypeCode"] && filters["accommodationTypeCode"].length'
			@reset='$emit("reset-filters", "accommodationTypeCode")'
			@update='updateFilter(items_accommodation, "accommodationTypeCode")'
		/>
		<sidebar-items
			:title='$t("account.sidebar.hotels.amenities")'
			:items="items_facilities"
			:reset='filters["facilitiesFilter"] && filters["facilitiesFilter"].length'
			@reset='$emit("reset-filters", "facilitiesFilter")'
			@update='updateFilter(items_facilities, "facilitiesFilter")'
		/>

		<div class='a-sidebar-items' style='margin-bottom: 0'>
			<div class="row">
				<div class="col">
					<p class='a-sidebar-title'>{{$t("account.sidebar.hotels.price")}}</p>
				</div>
			</div>
			<div v-for='(item, i) in sliders' :key='i'>
				<sidebar-slider
					type='total'
					:min='item.min'
					:max='item.max'
					:value='item.value'
					:range='item.range'
					:enable-cross="false"
					@change="item.value = $event, $emit('update-filters', {[item.key]: $event})"
					:reset='filters[item.key] && (item.range ? (filters[item.key][0] > item.min || filters[item.key][1] < item.max) : filters[item.key] < item.max)'
					@reset='$emit("reset-filters", [item.key]), item.value = (item.range ? [item.min, item.max] : item.max)'
					:items='items'
				/>
			</div>
		</div>

		<div class='a-sidebar-items'>
			<div class="row">
				<div class="col">
					<p class='a-sidebar-title'>{{$t("account.sidebar.hotels.hotel_chain")}}</p>
				</div>
				<div class="col-auto" v-if='filters["chainName"] && filters["chainName"].length'>
					<p class='app-link green--text' @click='chainName = "", $emit("reset-filters", "chainName")'>{{$t("account.buttons.cast")}}</p>
				</div>
			</div>

			<v-text-field
				:placeholder='$t("account.sidebar.hotels.filter_by_hotel_chain")'
				name='chainName'
				type='text'
				v-model='chainName'
				iconPrepend='search'
				:icon='chainName.length > 0 ? "cross" : ""'
				:iconAction='() => chainName = ""'
				:iconSize='12'
				isBorderFocus
			/>

			<template v-if='items_chainName.length === 0'>
				<p class='a-sidebar-total-title'>{{$t("account.sidebar.hotels.no_hotel_chain_were_found")}}</p>
			</template>
			<sidebar-items
				:items='items_chainName'
				:reset='filters["chainName"] && filters["chainName"].length'
				@reset='$emit("reset-filters", "chainName")'
				@update='updateFilter(items_chainName, "chainName")'
				v-else
			/>
		</div>

		<div class='a-sidebar-mobile-footer'>
			<v-button xxsmall color='green white--text' class='a-card-mobile-btn' @click='$emit("input", false)'>
				{{$t('account.buttons.choose')}}
			</v-button>
			<p class='a-sidebar-mobile-footer-text' v-if='filtersItems.length !== items.length'>{{$t("account.sidebar.results_found", {length: filtersItems.length})}}</p>
		</div>
	</div>
</template>

<script>
	import SidebarItems from '@/views/account/search/components/sidebar/items';
	import SidebarSlider from '@/views/account/search/components/sidebar/sliders';
	import _ from 'lodash'

	export default {
		data: () => ({
			name: '',
			zoneName: '',
			chainName: '',
		}),
		props: ["value", "items", "filters", "filtersItems"],
		computed: {
			source() {
				return JSON.parse(this.$route.query.source)
			},
			items_hotels() {
				let items = this.updateItems({
					key: 'name',
					total: false
				})
				return items.filter(item => item.name.toLowerCase().includes(this.name.toLowerCase()));
			},
			items_zoneName() {
				let items = this.updateItems({
					key: 'zoneName',
					total: false
				})
				return items.filter(item => item.name.toLowerCase().includes(this.zoneName.toLowerCase()));
			},
			items_chainName() {
				let items = this.updateItems({
					key: 'chainName',
					total: false
				})
				return items.filter(item => item.name.toLowerCase().includes(this.chainName.toLowerCase()));
			},
			items_boardCode() {
				let group = _.uniqBy(_.flattenDeep(this.items.map(el => el.offers)), 'boardCode');
				return this.updateItemsGroup(group, 'offers.boardCode', 'boardCode', 'boardName', 'offers');
			},
			items_categories() {
				return _.sortBy(this.updateItems({
					key: 'categorySimpleCode',
					name: false,
					func: this.nameCategory,
					total: true
				}), "id").reverse();
			},
			items_accommodation() {
				return this.updateItems({
					key: 'accommodationTypeCode',
					total: true
				});
			},
			items_facilities() {
				let group = _.uniqBy(_.flattenDeep(this.items.map(el => el.facilitiesFilter)));
				return this.updateItemsGroup(group, 'facilitiesFilter');
			}
		},
		components: {
			SidebarItems,
			SidebarSlider
		},
		created() {
			this.createSlider();
		},
		methods: {
			createSlider() {
				this.sliders = [
					{
						key: "minRate",
						value: [
							Math.ceil(_.minBy(this.items, 'minRate').minRate),
							Math.ceil(_.maxBy(this.items, 'minRate').minRate)
						],
						min: Math.ceil(_.minBy(this.items, 'minRate').minRate),
						max: Math.ceil(_.maxBy(this.items, 'minRate').minRate),
						range: true
					}
				]
			},
			updateOptions(key) {
				return this.$_.uniqBy(this.items, key).map(t => {
					return {
						id: String(this.$_.get(t, key)),
						name: this.$_.get(t, key)
					}
				})
			},
			updateItems({key, name, func, total}) {
				return this.$_.uniqBy(this.items, key).map(t => {
					return {
						id: String(this.$_.get(t, key)),
						name: func ? func(this.$_.get(t, key)) : this.$_.get(t, (name ?? key)),
						total: total ? this.items.filter(el => el[key] === this.$_.get(t, key)).length : null,
						active: this.filters[key] && this.filters[key].find(el => el == String(this.$_.get(t, key))) ? true : false,
						// disabled: !(this.filtersItems.find(el => this.$_.get(el, key) == this.$_.get(t, key)))
					}
				})
			},
			updateItemsGroup(group, key, id, name, itemsKey) {
				return group.map(t => {
					return {
						id: id ? t[id] : t,
						name: name ? t[name] : t,
						total: this.items.filter(el => el[itemsKey ?? key].find(item => (id ? item[id] : item) === (id ? t[id] : t))).length,
						active: this.filters[key] && this.filters[key].find(el => el === (id ? t[id] : t)) ? true : false,
						// disabled: !(this.filtersItems.filter(el => el[itemsKey ?? key].find(item => (id ? item[id] : item) === (id ? t[id] : t)))).length
					}
				})
			},
			updateFilter(items, key) {
				this.$emit("update-filters", {[key]: items.filter(el => el.active && !el.all).map(el => el.id)})
			},
			nameCategory(item) {
				return `<ul class='a-sidebar-stars-list'>
					${Array.from(Array(item).keys()).map(() => `
						<li class='a-sidebar-stars-item'>
							<span class="app-icon" style="width: 18px; height: 18px;">
								<svg viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">
									<path d="M7.52772 0.358693C7.68337 -0.0890874 8.31663 -0.0890865 8.47228 0.358694L10.0018 4.7589C10.0705 4.95643 10.2548 5.09037 10.4639 5.09463L15.1214 5.18954C15.5953 5.1992 15.791 5.80147 15.4133 6.08787L11.7011 8.90226C11.5344 9.0286 11.464 9.24532 11.5246 9.44549L12.8735 13.9043C13.0108 14.3581 12.4985 14.7303 12.1094 14.4595L8.28559 11.7987C8.11393 11.6793 7.88607 11.6793 7.71441 11.7987L3.89064 14.4595C3.50152 14.7303 2.98919 14.3581 3.12647 13.9043L4.47544 9.44548C4.536 9.24532 4.46559 9.0286 4.29894 8.90226L0.586742 6.08787C0.208976 5.80147 0.404667 5.1992 0.878629 5.18954L5.53612 5.09463C5.7452 5.09037 5.92955 4.95643 5.99821 4.7589L7.52772 0.358693Z" />
								</svg>
							</span>
						</li>
					`).join('')}
				</ul>`
			}
		},
		watch: {
			items() {
				this.createSlider();
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-sidebar {
		&-stars {
			&-list {
				display: flex;
				align-items: center;
			}
			&-item {
				margin: 0 3px;
				color: rgba($cyprus, .5);
			}
		}
	}
</style>
