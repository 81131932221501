<template>
	<transition name="translate-bottom">
		<div class='a-trips__compare' v-if='count > 0'>
			<div class='a-trips__compare-container app-container a-container'>
				<div class="row align-items-center">
					<div class="col">
						<div class='a-trips__compare-wrap'>
							<div class="row align-items-center">
								<div class="col">
									<div class='a-trips__compare-content'>
										<v-icon icon='graph' size='18' class='a-trips__compare-icon' />
										<p class='a-trips__compare-title'>{{$t("account.comparison.added_to_comparison")}} ({{count}}/{{check_availability.maxCount}})</p>
									</div>
								</div>
								<div class="col-auto">
									<div class='a-trips__compare-cancel' @click='clearComparison'>
										<v-icon icon='cross' size='10' class='a-trips__compare-cancel-icon' />
										<span class='a-trips__compare-cancel-title'>{{$t("account.comparison.deselect")}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-auto">
						<div class='a-trips__compare-action'>
							<v-button xxsmall outline color='green' @click='routerComparison'>{{$t("account.comparison.compare")}}</v-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	import { clearComparison } from '@/services/request';

	export default {
		props: ['check_availability', 'nameRoute'],
		computed: {
			count() {
				return (this.check_availability && this.check_availability.count) ?? 0;
			}
		},
		methods: {
			routerComparison() {
				this.$router.push({
					name: this.nameRoute,
					params: {
						comparisonId: this.check_availability.id,
						lang: this.$route.params.lang
					},
					query: {
						orderId: this.$route.params.orderId,
						storageId: this.$route.params.storageId,
						source: this.$route.query.source
					}
				})
			},
			clearComparison() {
				clearComparison(this.check_availability.id).then(() => this.$emit("clear-comparison"));
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-trips__compare {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: $cyan-light;
		z-index: 9;
		padding-bottom: env(safe-area-inset-bottom, 20px);
		&-container {
			display: flex;
			justify-content: space-between;
			padding-top: 15px;
			padding-bottom: 15px;
			max-width: 1635px;
			margin: auto;
			@include minw( $grid-breakpoints-xs ) {
				padding-top: 20px;
				padding-bottom: 20px;
			}
		}
		&-wrap {
			flex: 1 1 100%;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				.row {
					flex-direction: column;
					align-items: flex-start;
				}
			}
		}
		&-content {
			display: flex;
			align-items: center;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				margin-bottom: 4px;
			}
		}
		&-icon {
			flex-shrink: 0;
			margin-right: 15px;
			color: $green-persian;
		}
		&-title {
			font-size: 14px;
			font-weight: 400;
			line-height: 1.5;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 22px * .8;
				font-weight: 700;
			}
			@include minw( $grid-breakpoints-lg ) {
				font-size: 22px;
			}
		}
		&-cancel {
			display: flex;
			align-items: center;
			cursor: pointer;
			color: rgba($black, .6);
			transition: color $transition;
			margin-left: 33px;
			&:hover {
				color: $green-persian;
			}
			&-icon {
				flex-shrink: 0;
				margin-right: 10px;
			}
			&-title {
				font-weight: normal;
				font-size: 13px;
				line-height: 1.5;
			}
		}
		&-action {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				.app-btn {
					height: 37px;
					font-size: 14px;
				}
			}
		}
	}
</style>
