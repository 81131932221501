<template>
	<div class='tripadvisor'>
		<v-icon icon='tripadvisor' size='16' />
		<ul class='tripadvisor-list'>
			<li class='tripadvisor-item' v-for='(item, i) in 5' :key='i'>
				<span class='tripadvisor-item-dot'
					:class='{
						"full": item <= rating,
						"half": String(rating).split(".").length > 1 && item === Math.ceil(rating)
					}'
				/>
			</li>
		</ul>
		<p class='tripadvisor-review'>({{countRewards ? countRewards : 0}} {{$helpers.declOfNum(countRewards ? countRewards : 0, $t('account.declOfNum.rewards'))}})</p>
	</div>
</template>

<script>
	export default {
		props: ['rating', 'countRewards']
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.tripadvisor {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		&-list {
			display: flex;
			margin-left: 5px;
			margin-right: 10px;
		}
		&-item {
			margin: 1px;
			display: inline-block;
			&-dot {
				display: block;
				width: 9px;
				height: 9px;
				border-radius: 100%;
				border: 1px solid #00AF85;
				&:before {
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}
				&.full {
					&:before {
						background-color: #00AF85;
					}
				}
				&.half {
					&:before {
						width: 50%;
						background-color: #00AF85;
					}
				}
			}
		}
		&-review {
			font-size: 12px;
			font-weight: 400;
			color: rgba($black, .4);
		}
	}
</style>
