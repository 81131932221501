<template>
	<form class='a-booking-form-default' :class='{"a-booking-form-hotel-edit": edit}'>
		<div class='a-booking-form-row'>
			<div class="a-booking-form-col">
				<select-defaults
					:placeholder="$t('account.home.hotels.search')"
					prepend-icon='bed'
					v-model='form.initialHotel'
					@input='updateHotel()'
					:errors='!form.country && errors.country'
					:requestName='getAutocompleteDestinationsOrHotels'
				/>
			</div>
			<div class="a-booking-form-col">
				<a-date-picker
					v-model='form.date'
					@change='changeDate($event)'
					:errors='!form.date && errors.dateFrom'
					:placeholder="`${$t('account.form.placeholder.dateFrom')} — ${$t('account.form.placeholder.dateTo')}`"
					range
					right
					class='mobile-hide'
				/>
				<a-date-picker-mobile
					v-model='form.date'
					@change='changeDate($event)'
					:errors='!form.date && errors.dateFrom'
					:placeholder="`${$t('account.form.placeholder.dateFrom')} — ${$t('account.form.placeholder.dateTo')}`"
					range
					class='mobile-show'
				/>
			</div>
			<div class="a-booking-form-col" :style='(form.tourists && form.tourists.length) || hideChooseTourist ? "pointer-events: none; opacity: .5": ""'>
				<select-people
					:adults='form.adults'
					:childrenCount='form.childrenCount'
					:children='form.children'
					:placeholder="$t('account.home.hotels.count_tourists_and_rooms')"
					@update-people='$event => form = {...form, ...$event}'
					isRooms
					isSingleRoom
					:errors='!form.rooms.length && errors.rooms'
					:dropdown-right='edit'
					:tourists='form.tourists'
				/>
			</div>
			<div class="a-booking-form-col" v-if='!edit'>
				<a-autocomplete
					:placeholder="$t('account.home.hotels.nationality_tourists')"
					v-model='form.initialNationality'
					:requestName='getAutocompleteNationality'
					item-value='code'
					return-object
					actionInitial
					append-icon='arrow-down'
					:errors='!form.nationality && errors.nationality'
				/>
			</div>
		</div>

		<template v-if='!edit'>
			<select-tourist
				:initialTourists='form.tourists'
				@tourists='form.tourists = $event'
				@add-tourist='form.travelersIds = $event'
				@remove-tourist='form.travelersIds.splice($event, 1)'
			/>
		</template>

		<div class='row a-booking-form-action'>
			<div class="col">
				<template v-if='!edit'>
					<p class='a-booking-form-description' v-if='form.travelersIds.length >= 4'>{{$t("account.booking.content.max_number_tourists")}}</p>
				</template>
			</div>
			<div class="col-auto">
				<v-button :small='!edit' :xxsmall='edit' color='green white--text' @click='hotelSearch' :loading='loading'>{{$t("account.booking.content.search")}}</v-button>
			</div>
		</div>
	</form>
</template>

<script>
	import SelectDefaults from "@/views/account/home/components/SelectDefaults";
	import SelectPeople from "@/views/account/home/components/SelectPeople";
	import SelectTourist from '@/views/account/home/components/SelectTourist';
	import { getAutocompleteDestinationsOrHotels, getAutocompleteNationality, hotelSearch } from '@/services/request';

	export default {
		data: () => ({
			open: false,
			form: {
				rooms: [],
				country: null,
				destination: null,
				date: null,
				dateFrom: null,
				dateTo: null,
				night: null,
				travelersIds: [],
				resetCache: false,
				initialHotel: null,
				initialNationality: null,
				nationality: null,
				showSimilar: false,
				tripName: null,
				tourists: []
			},
			loading: false,
			errors: {}
		}),
		props: ["edit", "searchData", "initialTripName", "hideChooseTourist"],
		components: {
			SelectDefaults,
			SelectPeople,
			SelectTourist
		},
		computed: {
			local_search_data() {
				return localStorage.getItem('local_search_data') ? JSON.parse(localStorage.getItem('local_search_data')) : {};
			}
		},
		created() {
			if(this.local_search_data.hotels) {
				this.form = {...this.form, ...this.local_search_data.hotels};
			}
			if(this.$route.query.source) {
				this.form = JSON.parse(this.$route.query.source)
			} else if(this.searchData) {
				this.form = {...this.form, ...this.searchData};
			}
			if(this.form.initialHotel) {
				this.updateHotel()
			}
			this.tripName = this.$route.query?.tripName ?? this.initialTripName;

			if(this.form.tourists?.length === 0 && !this.edit) {
				this.form.travelersIds = [];
			}

			this.form.childrenCount = this.form.children.length
		},
		methods: {
			getAutocompleteDestinationsOrHotels,
			getAutocompleteNationality,
			updateHotel() {
				this.form.country = this.form.initialHotel.countryCode ?? null;
				this.form.destination = this.form.initialHotel.destinationCode ?? null;
				this.form.zone = this.form.initialHotel.type === 'zone' ? this.form.initialHotel.code : null;
				this.form.hotel = this.form.initialHotel.type === 'hotel' ? this.form.initialHotel.id : null;
				this.form.showSimilar = this.form.initialHotel.type === 'hotel';
			},
			hotelSearch() {
				if(!this.form.nationality && !this.edit)
					return this.errors = { nationality: '' }

				if(this.$store.state.notification.isActive) this.$store.commit('notification/clearMessage');
				this.loading = true;
				let orderId = this.$route.params.orderId ? { orderId: this.$route.params.orderId } : false;

				hotelSearch({...this.form, ...orderId}).then(res => {
					if(this.$route.params.storageId === res.data.storageId) {
						this.$emit("update");
					} else {
						this.$router.push({
							name: "hotels-search",
							params: {orderId: res.data.orderId, storageId: res.data.storageId, lang: this.$route.params.lang},
							query: {source: JSON.stringify(this.form), tripName: this.tripName}
						})
						localStorage.setItem('local_search_data', JSON.stringify({...this.local_search_data, hotels: this.form}));
					}
				}).catch(err => {
					this.errors = err.response.data?.errors ?? {};
				}).finally(() => this.loading = false)
			},
			changeDate($event) {
				this.form.date = $event[0] ? $event : null;
				this.form.dateFrom = this.$moment($event[0]).format('YYYY-MM-DD');
				this.form.dateTo = this.$moment($event[1]).format('YYYY-MM-DD');
				this.form.night = this.$moment($event[1]).diff(this.$moment($event[0]), 'days');
			}
		},
		watch: {
			"form.initialNationality"() {
				this.form.nationality = this.form.initialNationality?.code;
			},
			"form.tourists"() {
				localStorage.setItem('local_search_data', JSON.stringify({...this.local_search_data, hotels: this.form}));
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-booking-form-hotel {
		&-edit {
			.a-booking-form-row {
				padding-bottom: 10px;
			}
			.a-booking-form-col {
				@include minw( $grid-breakpoints-xs ) {
					&:first-child {
						flex: 0 0 100%;
						max-width: 100%;
					}
					&:not(:first-child) {
						flex: 0 0 calc(100% / 2);
						max-width: calc(100% / 2);
					}
				}
				@include minw( $grid-breakpoints-sm ) {
					&:first-child {
						flex: none;
						flex-basis: 0;
						flex-grow: 1;
					}
					&:not(:first-child) {
						flex: 0 0 296px;
						max-width: 296px;
					}
				}
			}
		}
	}
</style>
