<template>
	<div>
		<div class='a-trips'>
			<div class='app-container app-container-fluid a-container'>
				<div class="a-trips__container">
					<trips-header @click='edit = $event' :progress='getProgress'>
						<template v-slot:status>
							<p class='a-trips__header-title bold' v-if='!lastResult'>{{$t('account.search.download_results')}}</p>
							<p class='a-trips__header-title' v-else-if='initialItems.length === 0'>{{$t('account.search.total_number_of_options')}}: <span>(0)</span></p>
							<p class='a-trips__header-title bold' v-else>{{$t('account.search.choose_want.hotel')}}</p>
						</template>
						<template v-slot:title>
							<p class='a-trips__header-title'>{{source.initialHotel.name}}</p>
						</template>
						<template v-slot:info>
							<p class='a-trips__header-text'>
								{{$moment(source.dateFrom).format("DD/MM/YYYY")}} - {{$moment(source.dateTo).format("DD/MM/YYYY")}} • {{title}}
							</p>
						</template>
					</trips-header>

					<trips-preloader
						name='hotels'
						:animationDisabled='lastResult'
						:title='!lastResult ? $t("account.search.preloader.hotels.checking") : $t("account.search.preloader.hotels.no_available")'
						:text='!lastResult ? `${$t("account.search.preloader.we_check_hotels_of_options")}...` : $t("account.search.preloader.we_could_not_find_the_results")'
						v-if='!progress.some(() => true) || initialItems.length === 0'
					>
						<template v-slot:list v-if='lastResult'>
							<v-button small color='green white--text' :to="$i18nRoute({ name: 'hotels' })">{{$t('account.search.change_search')}}</v-button>
							<ul class='a-trips-search-list'>
								<li class='a-trips-search-list-item'>{{$t('account.search.try_choose_alternate_dates')}}</li>
							</ul>
						</template>
					</trips-preloader>

					<trips-skeleton-card
						v-else-if='loading'
					/>

					<template v-else>
						<div class='row flex-nowrap'>
							<div class="col-auto a-trips-sidebar-col-auto">
								<trips-sidebar
									v-model='mobile_sidebar'
									:items='initialItems'
									:filters='filters'
									:filtersItems='filtersItems'
									@update-filters='updateFilter($event)'
									@reset-filters='resetFilter($event)'
									@clear-filters='filters = {}'
								/>
							</div>
							<div class='col'>
								<div class="a-trips__main">
									<trips-sorting
										v-model='mobile_sorting'
										:items='initialItems'
										:filtersItems='filtersItems'
										:active_sorting='active_sorting'
										@active_sorting='$event => (active_sorting = $event, page = 1)'
									/>

									<div class='row'>
										<div class="col">
											<v-button block xxsmall outline color='green' class='tablet-show a-card-mobile-btn' @click='mobile_sidebar = true'>{{$t("account.sidebar.filters")}}</v-button>
										</div>
										<div class="col">
											<v-button block xxsmall outline color='green' class='tablet-show a-card-mobile-btn' @click='mobile_sorting = true'>{{$t("account.buttons.sorting")}}</v-button>
										</div>
									</div>

									<div class='a-trips-content'>
										<div v-for='(item, i) in paginationItems' :key='i'>
											<template v-if='source.initialHotel.type === "hotel" && i === 0 && page === 1 && item.name !== source.initialHotel.name.toUpperCase() && Object.keys(filters).length === 0'>
												<v-alert
													color='orange'
													border='left'
													outlined
													icon='navigation/hotels'
												>
													<p class='v-alert-text'>{{$t("account.search.hotels.no_found_hotel")}}</p>
												</v-alert>
											</template>

											<trips-card
												:item='item'
												:items_favourite='items_favourite'
												@update-favourite='hotelFavorite'
												:check_availability='check_availability'
												@update-comparison='check_availability = $event'
											/>

											<template v-if='source.initialHotel.type === "hotel" && i === 0 && page === 1 && item.name === source.initialHotel.name.toUpperCase() && filtersItems.length > 1'>
												<p class='a-trips-content-title'>{{$t("account.search.hotels.you_may_also_like_it")}}</p>
											</template>
										</div>

										<v-pagination
											:total='filtersItems.length'
											:page='page'
											:perPage='perPage'
											@update-pagination='page = $event'
											v-if='filtersItems.length'
										/>

										<trips-preloader
											name='hotels'
											:animationDisabled='true'
											:title='$t("account.search.preloader.hotels.no_found")'
											v-if='paginationItems.length === 0'
										/>
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>

		<trips-edit
			:title='$t("account.search.title.hotels")'
			v-model='edit'
			@input='edit = $event'
		>
			<template>
				<a-home-hotels :edit='true' @update='edit = false, getHotelSearch()' />
			</template>
		</trips-edit>

		<trips-comparison
			:check_availability='check_availability'
			@clear-comparison='check_availability = {}'
			nameRoute='comparison-hotels'
		/>
		<trips-time @update-search='hotelSearch'
			link='hotels'
			:title='$t("account.search.hotels.number")'
			:text='$t("account.search.hotels.numbers")'
			:edit="edit"
		/>
	</div>
</template>

<script>
	import TripsHeader from '@/views/account/search/components/header';
	import TripsPreloader from '@/views/account/search/components/preloader';
	import TripsSkeletonCard from '@/views/account/search/hotels/skeleton-card';
	import TripsSidebar from '@/views/account/search/hotels/sidebar';
	import TripsSorting from '@/views/account/search/hotels/sorting';
	import TripsCard from '@/views/account/search/hotels/card';
	import TripsEdit from '@/views/account/search/components/edit';
	import AHomeHotels from "@/views/account/home/hotels";
	import TripsTime from '@/views/account/search/components/time';
	import TripsComparison from '@/views/account/search/components/comparison';
	import { getHotelSearch, hotelFavorite, comparisonCheckAvailability, hotelSearch } from '@/services/request';
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';
	import _ from 'lodash'
	import { i18n } from '@/plugins/i18n';

	export default {
		data: () => ({
			setTimeout: null,
			loading: false,
			active_card: null,
			card_details: false,
			initialItems: [],
			filters: {},
      page: 1,
      perPage: 10,
			lastResult: false,
			progress: [],
			active_sorting: "ranking",
			mobile_sidebar: false,
			mobile_sorting: false,
			edit: false,
			check_availability: {},
			items_favourite: []
		}),
		components: {
			TripsHeader,
			TripsPreloader,
			TripsSkeletonCard,
			TripsSidebar,
			TripsSorting,
			TripsCard,
			TripsEdit,
			AHomeHotels,
			TripsTime,
			TripsComparison
		},
		computed: {
			getProgress() {
				if(!this.progress) return;
				return this.progress.filter(el => el === true).length * 100 / this.progress.length;
			},
			source() {
				if(!this.$route.query.source) return;
				return JSON.parse(this.$route.query.source);
			},
			title() {
				let totalTourist = this.source.adults + this.source.childrenCount;
				return `${totalTourist} ${this.$helpers.declOfNum(totalTourist, i18n.t('account.declOfNum.tourists'))}, ${i18n.t('account.search.hotels.one_room')}`
			},
			filtersItems() {
				return _.flattenDeep(Object.values(this.filters)).length ? this.updateItems() : this.initialItems;
			},
			paginationItems() {
				return _.sortBy(this.filtersItems, this.active_sorting === "discount" ? [(item) => _.minBy(item.offers, 'amount').discount] : this.active_sorting)
					.sort((first) => { if(first.translit == this.source.initialHotel.code) return -1; })
					.slice(this.page === 1 ? 0 : this.perPage * (this.page - 1), this.perPage * this.page);
			}
		},
		created() {
			this.getHotelSearch();
			this.hotelFavorite();
			this.comparisonCheckAvailability();
		},
		methods: {
			updateFilter($event) {
				this.filters = {...this.filters, ...$event};
				this.page = 1;
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});
				this.updateItems();
			},
			resetFilter(name) {
				delete this.filters[name];
				this.updateFilter();
			},
			filterArray(array, filters) {
				const filterKeys = Object.keys(filters);
				return array.filter((item) => filterKeys.every((key) => {
					if(filters[key] === null || filters[key].length === 0) return item;

					if(key == 'offers.boardCode') {
						return filters[key].filter(t => item.offers.find(item => item.boardCode === t)).length;
					}
					if(key == 'facilitiesFilter') {
						return filters[key].filter(t => _.get(item, key).find(item => item === t)).length;
					}
					if(key == 'minRate') {
						return filters[key][0] <= Math.ceil(_.get(item, key)) && filters[key][1] >= Math.ceil(_.get(item, key));
					}
					// if(key == 'chainName') {
					// 	return filters[key] === _.get(item, key);
					// }

					return filters[key].indexOf(String(_.get(item, key))) !== -1
				}));
			},
			updateItems() {
				return this.filterArray(this.initialItems, this.filters)
			},
			getHotelSearch(loading) {
				this.loading = loading ?? !(this.progress.some(() => true) && this.initialItems.length);

				getHotelSearch(this.$route.params.storageId).then(res => {
					if(res.data !== null) {
						this.initialItems = res.data.offers;

						this.lastResult = res.data.lastResult;
						this.progress = res.data.progress;


						if(!this.lastResult) {
							let isLoading = this.source.initialHotel.type === "hotel" && !(this.filtersItems.length && this.filtersItems[0].name === this.source.initialHotel.name.toUpperCase());
							this.setTimeout = setTimeout(() => this.getHotelSearch(isLoading), 2500);
						}else{
							this.loading = false;
						}

						if(this.progress.some(() => true) && this.initialItems.length && this.source.initialHotel.type !== "hotel") {
							setTimeout(() => this.loading = false, 5000);
						}
					} else {
						this.initialItems = [];
						this.lastResult = true;
						this.progress = [];
					}
				})
			},
			hotelFavorite() {
				hotelFavorite().then(res => this.items_favourite = res.data);
			},
			comparisonCheckAvailability() {
				comparisonCheckAvailability({
					type: 'hotel',
					orderId: this.$route.params.orderId,
					storageId: this.$route.params.storageId
				}).then(res => this.check_availability = res.data ?? {});
			},
			hotelSearch() {
				if(this.$store.state.notification.isActive) this.$store.commit('notification/clearMessage');
				this.loading = true;

				hotelSearch(this.source).then(res => {
					if(this.$route.params.storageId === res.data.storageId) {
						this.getHotelSearch(true);
					} else {
						this.$router.push({
							name: "hotels-search",
							params: {orderId: res.data.orderId, storageId: res.data.storageId, lang: this.$route.params.lang},
							query: {source: JSON.stringify(this.form), tripName: this.tripName}
						})
						localStorage.setItem('local_search_data', JSON.stringify({...this.local_search_data, hotels: this.form}));
					}
				})
			}
		},
		watch: {
			mobile_sidebar() {
				setTimeout(() => {
					const hideEl = document.querySelector('.a-sidebar')
					this.mobile_sidebar ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
				}, 150)
			},
			mobile_sorting() {
				setTimeout(() => {
					const hideEl = document.querySelector('.a-trips__sorting')
					this.mobile_sorting ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
				}, 150)
			},
			"$route.params.storageId"() {
				this.edit = false;
				this.initialItems = [];
				this.active_card = null;
				this.card_details = false;
				this.page = 1;
				this.lastResult = false;
				this.progress = [];
				this.filters = {};

				this.getHotelSearch();
				this.hotelFavorite();
				this.comparisonCheckAvailability();
			}
		},
		beforeDestroy() {
			clearTimeout(this.setTimeout)
		}
	}
</script>
