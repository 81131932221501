<template>
	<trips-skeleton>
		<div class='a-card' v-for='el in 3' :key='el'>
			<div class='h-card-wrap'>
				<div class='h-card-image link'>
					<span class='skeleton' :style='`width: 100%; height: 100%`' />
				</div>
				<div class='h-card-container'>
					<div class='h-card-content'>
						<ul class='h-card-stars-list'>
							<li class='h-card-stars-item'>
								<span class='skeleton' :style='`width: 67px; height: 12px`' />
							</li>
						</ul>
						<p class='h-card-title link'><span class='skeleton' :style='`width: 165px; height: 22px`' /></p>
						<div class="h-card-info">
							<span class='skeleton' :style='`max-width: 264px; height: 14px; margin-bottom: 20px`' />
						</div>

						<div class="h-card-content-info desktop-show">
							<span class='skeleton' :style='`width: 111px; height: 14px; margin-bottom: 5px`' v-for='el in 2' :key='el' />
						</div>

						<span class='skeleton' :style='`width: 136px; height: 22px`' />
					</div>
					<div class="h-card-group-buttons">
						<div class="col">
							<span class='skeleton desktop-show' :style='`width: 100%; height: 40px; margin-top: 15px; margin-bottom: 15px;`' />
							<span class='skeleton desktop-hide' :style='`width: 168px; height: 24px; margin-bottom: 15px; margin-left: auto;`' />
						</div>
						<div class="col">
							<span class='skeleton desktop-show' :style='`width: 100%; height: 40px; margin-top: 15px; margin-bottom: 15px;`' />
							<span class='skeleton desktop-hide' :style='`width: 189px; height: 40px`' />
						</div>
					</div>
				</div>
			</div>

			<div class="h-card-footer">
				<div class="h-card-footer-row">
					<div class="h-card-footer-info desktop-hide">
						<p class='a-text-with-icon green--text' v-for='el in 3' :key='el'>
							<span class='skeleton' :style='`width: 168px; height: 14px`' />
						</p>
					</div>
					<div class="h-card-footer-action">
						<div>
							<span class='skeleton desktop-show' :style='`width: 112px; height: 20px; margin-bottom: 5px;`' />
							<span class='skeleton desktop-hide' :style='`width: 112px; height: 20px; margin-bottom: 5px; margin-left: auto`' />
							<span class='skeleton' :style='`width: 147px; height: 13px;`' />
						</div>
						<div class="h-card-footer-action-button">
							<span class='skeleton' :style='`width: 277px; height: 47px`' />
						</div>
					</div>
				</div>
			</div>
		</div>
	</trips-skeleton>
</template>

<script>
	import TripsSkeleton from '@/views/account/search/components/skeleton';

	export default {
		components: {
			TripsSkeleton
		}
	}
</script>
